import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import operation from 'redux/home/home-operation';
import s from './style.module.scss';
import { useSelector } from 'react-redux';
import homeSelectors from 'redux/home/home-selectors';
import operationData from 'redux/home/home-operation';

export const Timer = ({ getText, unix }) => {
  const { current, end, start } = unix;
  const [time, setTime] = useState(null);
  const [isOver, setIsOver] = useState(false);
  const [dontStart, setDontStart] = useState(false);
  const dispatch = useDispatch();
  const IsVoting = useSelector(homeSelectors.getIsVoting);

  useEffect(() => {
    let deltaTime = end - current;
    let deltaTimeStart = start - current;

    const refreshIntervalId = setInterval(() => {
      deltaTimeStart = deltaTimeStart - 1000;
      deltaTime = deltaTime - 1000;
      if (start > current) {
        setDontStart(true);
        if (deltaTimeStart <= 0) {
          setDontStart(false);
          dispatch(operationData.getData({}));
          setTime(null);
          dispatch(operation.changeIsVoting({ isVotingActive: true }));
        }
      } else if (deltaTime <= 0 || (start > current && current < end)) {
        setIsOver(true);
        setDontStart(false);
        dispatch(operation.changeIsVoting({ isVotingActive: true }));
        dispatch(operation.changeUserCanVote({ userCanVote: false }));

        clearInterval(refreshIntervalId);
      } else if (deltaTime >= 0 && deltaTimeStart <= 0) {
        if (!IsVoting) {
          dispatch(operation.changeIsVoting({ isVotingActive: true }));
        }
      }

      if (start > current) {
        if (deltaTimeStart <= 0) return;
        getTimeComponents(deltaTimeStart);
      } else {
        getTimeComponents(deltaTime);
      }
    }, 1000);

    return () => clearInterval(refreshIntervalId);
  }, [end, current, start]);

  function getTimeComponents(time) {
    const hours = pad(
      Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
    );
    const mins = pad(Math.floor((time % (1000 * 60 * 60)) / (1000 * 60)));
    const secs = pad(Math.floor((time % (1000 * 60)) / 1000));

    return setTime({ hours, mins, secs });
  }

  const pad = value => {
    return String(value).padStart(2, '0');
  };

  return (
    <>
      {time === null || Object.keys(unix).length === 0 || isNaN(time.hours) ? (
        <div className={s.loading}> Loading...</div>
      ) : isOver ? (
        <strong className={s.massage}>{getText('find_timer')}</strong>
      ) : (
        <strong className={s.wrap}>
          {dontStart ? (
            <h3 className={s.title}>{getText('start_timer')}</h3>
          ) : (
            <h3 className={s.title}>{getText('description_timer')}</h3>
          )}

          <p
            className={s.timer}
          >{`${time?.hours}:${time?.mins}:${time?.secs}`}</p>
        </strong>
      )}
    </>
  );
};
