import { createSlice } from '@reduxjs/toolkit';
import operation from './auth-operation';

const initialState = {
  userName: null,
  userEmailGoogle: null,
  userEmailFacebook: null,
  token: null,
  isLoggedGoogleIn: false,
  isLoggedFacebookIn: false,
  isRefreshing: true,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  extraReducers: {
    //====signIn====//

    [operation.signIn.fulfilled]: (state, { payload }) => {
      state.token = payload.token;
    },
    //====googleIn====//
    [operation.googleIn.pending]: (state, _) => {
      state.isLoggedGoogleIn = true;
    },
    [operation.googleIn.fulfilled]: (state, { payload }) => {
      state.userEmailGoogle = payload.email;
      state.userName = payload.name;
      state.isLoggedGoogleIn = false;
    },
    [operation.googleIn.rejected]: (state, _) => {
      state.isLoggedGoogleIn = false;
    },
    //====facebookIn====//
    [operation.facebookIn.pending]: (state, _) => {
      state.isLoggedFacebookIn = true;
    },
    [operation.facebookIn.fulfilled]: (state, { payload }) => {
      state.userEmailFacebook = payload.email;
      state.userName = payload.name;
      state.isLoggedFacebookIn = false;
    },
    [operation.facebookIn.rejected]: (state, _) => {
      state.isLoggedFacebookIn = false;
    },
    //====refreshCurrentUser====//
    [operation.refreshCurrentUser.pending]: (state, action) => {
      state.isRefreshing = true;
    },
    [operation.refreshCurrentUser.fulfilled]: (state, { payload }) => {
      state.token = payload;
      state.isRefreshing = false;
    },
    [operation.refreshCurrentUser.rejected]: (state, { payload }) => {
      state.token = null;
      state.isRefreshing = false;
    },
    //====clearToken====//
    [operation.clearToken.fulfilled]: (state, { payload }) => {
      state.token = null;
    },
  },
});
export default authSlice.reducer;
