import { useState } from 'react';
import { Container, ActionBtn } from 'component';
import s from './style.module.scss';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import personalCabSelectors from 'redux/personalCab/personalCab-selectors';
import personalCabOperation from 'redux/personalCab/personalCab-operation';
import authOperation from 'redux/auth/auth-operation';
import { QuestionModal } from 'modules';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { profileLogo } from 'assets/images';

export const Profile = () => {
  const dispatch = useDispatch();
  const profile = useSelector(personalCabSelectors.getProfile);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const deleteProfile = () => {
    new Promise((resolve, reject) => {
      dispatch(
        personalCabOperation.deleteProfile({
          onResolve: resolve,
          onReject: reject,
        }),
      );
    })
      .then(data => {
        dispatch(authOperation.clearToken());
        history.push('/');
      })
      .catch(error =>
        toast.error(`${error.response.data.error.message}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }),
      )
      .finally(() => {
        setShowModal(false);
      });
  };

  return (
    <main className={s.main}>
      <section>
        <Container>
          <h1 className={s.mainTitle}>{t('profile.profile_title')}</h1>

          <div className={s.card}>
            <div>
              <p className={s.profileText}>{t('profile.login_with')}</p>
              <div className={s.dataProfile}>
                {profile?.name && (
                  <p className={s.profileText}>
                    <span>{t('profile.name')}</span>
                    {profile.name},
                  </p>
                )}
                {profile?.email && (
                  <p className={s.profileText}>
                    <span>{t('profile.email')}</span>
                    {profile?.email},
                  </p>
                )}
                {profile?.phone && (
                  <p className={s.profileText}>
                    <span>{t('profile.phone')}</span>
                    {profile?.phone},
                  </p>
                )}
              </div>

              <strong className={s.warningText}>
                {t('profile.delete_profile')}
              </strong>
              <div className={s.wrap}>
                <ActionBtn
                  type={'button'}
                  text={t('profile.delete_btn')}
                  active={true}
                  handleChange={toggleModal}
                />
              </div>
            </div>
            <div className={s.imageThumb}>
              <img src={profileLogo} alt="Logo" width="260" />
            </div>
          </div>
        </Container>
      </section>
      {showModal && (
        <QuestionModal
          close={toggleModal}
          questionText={t('modal_profile.question')}
          rejectText={t('modal_profile.reject')}
          acceptText={t('modal_profile.accept')}
          actionHandel={deleteProfile}
        />
      )}
    </main>
  );
};
