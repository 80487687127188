import { ArrowBtn } from 'component';
import s from './style.module.scss';
export const Paginate = ({
  children,
  back,
  next,
  isPaginate,
  isNext,
  isBack,
}) => {
  return (
    <>
      {children}
      {isPaginate && (
        <div className={s.paginateWrap}>
          {isBack && (
            <ArrowBtn type={'back'} handelBack={back} active={isBack} />
          )}
          {isNext && (
            <ArrowBtn type={'next'} handelNext={next} active={isNext} />
          )}
        </div>
      )}
    </>
  );
};
