import s from './style.module.scss';
import { FacebookShareButton, FacebookIcon } from 'react-share';

export const FBShare = ({ name, id, version }) => {
  return (
    <FacebookShareButton
      url={`https://levmatchu.uaf.ua/player/${id}`}
      quote={`Я голосую за ${name}`}
    >
      <FacebookIcon
        className={s.fbLogo}
        size={version === 'mobile' ? 24 : 40}
        round={true}
      />
    </FacebookShareButton>
  );
};
