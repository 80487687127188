import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import authSelectors from 'redux/auth/auth-selectors';
import PropTypes from 'prop-types';

export function PrivateRoute({ children, ...routeProps }) {
  const token = useSelector(authSelectors.getUserToken);
  return (
    <Route {...routeProps}>{token ? children : <Redirect to="/" />}</Route>
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node,
  routeProps: PropTypes.node,
};
