import s from './style.module.scss';
import { useState } from 'react';
import { Logo, City, CityMob } from 'assets/images';
import { RuleDoc } from 'assets/docs';
import { useTranslation } from 'react-i18next';
import { RuleItem, Container, ActionBtn } from 'component';
import { ruleKey } from 'conf/key';
import { Size } from 'middleware';
import { NavLink } from 'react-router-dom';

export const Footer = ({ isClip = false }) => {
  const { t } = useTranslation();
  const [size, setSize] = useState(0);

  return (
    <footer style={isClip ? { clipPath: 'none' } : { clipPath: 'border-box' }}>
      <Size setSize={setSize}>
        <Container>
          <h2 className={s.tittle}>
            <span>{t('footer_tittle')}</span>
          </h2>
          <div className={s.wrap}>
            <img className={s.logo} src={Logo} alt="logo" loading="lazy" />

            <ul className={s.listRule}>
              {ruleKey.map((key, index) => (
                <RuleItem
                  key={index}
                  index={index + 1}
                  text={t(`rules.${key}`)}
                />
              ))}
              <li className={s.ruleLink}>
                <ActionBtn
                  type="link"
                  text={t('link_detail_rule')}
                  typeLink="linkFooter"
                  refer={RuleDoc}
                  target={'_blank'}
                />
              </li>
            </ul>
          </div>
          <picture>
            <source srcSet={City} media="(min-width: 768px)" />
            <img
              className={s.city}
              srcSet={CityMob}
              src={CityMob}
              alt="City"
              loading="lazy"
            />
          </picture>

          <strong className={s.confident}>
            <span>&#169;</span>
            {t('confident')}
          </strong>

          <NavLink to={'/policy'} className={s.policy}>
            {t('policy')}
          </NavLink>
        </Container>
      </Size>
    </footer>
  );
};
