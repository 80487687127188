import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Size } from 'middleware';
import { Loader } from 'component';
import { Header, Footer, NewsMain } from 'modules/index';
import operationData from 'redux/home/home-operation';
import homeSelectors from 'redux/home/home-selectors';
import { toast } from 'react-toastify';

export const NewsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [size, setSize] = useState(0);
  const { t, i18n } = useTranslation();
  const refLang = useRef();
  const dispatch = useDispatch();
  const news = useSelector(homeSelectors.getNews);

  useEffect(() => {
    dispatch(operationData.getInfo());
    const refreshIntervalId = setInterval(() => {
      dispatch(operationData.getData({}));
    }, 120000);
    refLang.current = i18n.language;
    return () => clearInterval(refreshIntervalId);
  }, []);

  useEffect(() => {
    if (refLang.current === i18n.language) return;
    refLang.current = i18n.language;
    dispatch(operationData.changeLang());
  }, [i18n.language]);

  useEffect(() => {
    if (news) {
      setIsLoading(false);
    }
  }, [news]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('site_title')}</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <div
          className="adaptive"
          style={
            size < 768
              ? {
                  transform: `scale(${size / 250})`,
                  transformOrigin: 'top left',
                }
              : {
                  transform: `scale(${size / 1920})`,
                  transformOrigin: 'top left ',
                }
          }
        >
          <Size setSize={setSize}>
            <Header />
            <NewsMain />
            <Footer />
          </Size>
        </div>
      )}
    </>
  );
};
