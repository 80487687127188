import React from 'react';
import { Modal } from 'component';
import Turnstile from '../Turnstile';
import s from './style.module.scss';

const CaptchaModal = ({ onClose, onVerify, onError, onExpire }) => {
  return (
    <Modal onClose={onClose}>
      <div className={s.captchaContainer}>
        <h2 className={s.title}>Будь ласка, підтвердіть, що ви не робот</h2>
        <Turnstile
          onVerify={onVerify}
          onError={onError}
          onExpire={onExpire}
          className={s.turnstile}
        />
      </div>
    </Modal>
  );
};

export default CaptchaModal; 