import { useEffect } from 'react';
import { HomePage, NewsPage, PersonalCab, PolicyPage } from 'views';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { Suspense } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import operation from 'redux/auth/auth-operation';
import authSelectors from 'redux/auth/auth-selectors';
import { Loader } from 'component';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { personalFootball } from 'assets/images';
import { NavLink } from 'react-router-dom';
import { PrivateRoute } from 'middleware';

//Localisaton
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'uk'],
    fallbackLng: 'uk',
    debug: false,
    detection: {
      order: ['localStorage'],
      caches: ['localStorage'],
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    },
  });

const App = () => {
  const isUserLogin = useSelector(authSelectors.getUserToken);
  const isRefreshing = useSelector(authSelectors.getIsRefreshing);
  const dispatch = useDispatch();
  const location = useLocation();
  const slag = location.pathname.split('/')[1];

  useEffect(() => {
    dispatch(operation.refreshCurrentUser());
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <ToastContainer />
      {!isRefreshing && (
        <>
          <Switch>
            <PrivateRoute path="/personal-cabinet" exact>
              <PersonalCab />
            </PrivateRoute>
            <PrivateRoute path="/personal-cabinet/:lang" exact>
              <PersonalCab />
            </PrivateRoute>
            <Route path="/policy" exact component={PolicyPage} />
            <Route path="/policy/:lang" exact component={PolicyPage} />
            <Route path="/" exact component={HomePage} />
            <Route path="/:lang" exact component={HomePage} />
            <Route path="/news/:slug" exact component={NewsPage} />
            <Route path="/news/:slug/:lang" exact component={NewsPage} />
            <Redirect from="/" to="/" />
          </Switch>
          {/*{isUserLogin && slag !== 'personal-cabinet' && (*/}
          {/*  <NavLink to={'/personal-cabinet'} className="help">*/}
          {/*    <img*/}
          {/*      className="personalCab"*/}
          {/*      src={personalFootball}*/}
          {/*      alt="Personal Cabinet"*/}
          {/*    />*/}
          {/*  </NavLink>*/}
          {/*)}*/}
        </>
      )}
    </Suspense>
  );
};

export default App;
