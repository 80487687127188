import React, { useEffect, useRef } from 'react';

const Turnstile = ({
  onVerify,
  onError,
  onExpire,
  className,
}) => {
  const containerRef = useRef(null);
  const widgetIdRef = useRef();

  useEffect(() => {
    const checkTurnstile = () => {
      if (!window.turnstile) {
        console.error('Turnstile not loaded');
        return false;
      }
      return true;
    };

    const initTurnstile = () => {
      if (!checkTurnstile()) {
        setTimeout(initTurnstile, 100);
        return;
      }

      try {
        console.log('Initializing Turnstile with sitekey:', process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY);
        widgetIdRef.current = window.turnstile.render(containerRef.current, {
          sitekey: process.env.REACT_APP_CLOUDFLARE_TURNSTILE_SITE_KEY,
          callback: (token) => {
            console.log('Turnstile verified:', token);
            onVerify(token);
          },
          'error-callback': () => {
            console.error('Turnstile error');
            onError?.();
          },
          'expired-callback': () => {
            console.log('Turnstile expired');
            onExpire?.();
          },
          theme: 'light',
          size: 'normal',
          execution: 'render',
          refreshExpired: 'auto'
        });
      } catch (error) {
        console.error('Error initializing Turnstile:', error);
      }
    };

    initTurnstile();

    return () => {
      if (widgetIdRef.current && window.turnstile) {
        window.turnstile.reset(widgetIdRef.current);
      }
    };
  }, [onVerify, onError, onExpire]);

  return <div ref={containerRef} className={className} />;
};

export default Turnstile; 