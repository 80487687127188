import { BsChevronRight, BsChevronLeft } from 'react-icons/bs';
import s from './style.module.scss';

export const ArrowBtn = ({ type, handelBack, handelNext, active }) => {
  return (
    <>
      {type === 'back' ? (
        <button
          type="button"
          onClick={handelBack}
          className={s.btn}
          disabled={!active}
        >
          <BsChevronLeft
            className={s.icon}
            color={active ? '#C59B5A' : '#a3a09b'}
          />
        </button>
      ) : (
        <button
          type="button"
          onClick={handelNext}
          className={s.btn}
          disabled={!active}
        >
          <BsChevronRight
            className={s.icon}
            color={active ? '#C59B5A' : '#a3a09b'}
          />
        </button>
      )}
    </>
  );
};
