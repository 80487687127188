import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

const getNews = createAsyncThunk(
  'news/news',
  async ({ id }, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get(`news/${id}`, {
        headers: { 'Content-Language': `${lang}` },
      });
      return data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

const changeLang = createAsyncThunk(
  'news/changeLang',
  async ({ id }, { rejectWithValue }) => {
    try {
      const lang = localStorage.getItem('i18nextLng');
      const { data } = await axios.get(`news/${id}`, {
        headers: { 'Content-Language': `${lang}` },
      });
      return data;
    } catch (error) {
      toast.error(`${error.response.data.error.message}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return rejectWithValue(error);
    }
  },
);

export default {
  getNews,
  changeLang,
};
