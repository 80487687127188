import s from './style.module.scss';
import {
  LvivskeBear,
  LvivskeBearReserve,
  Ukrainefederation,
  UkrainefederationReserve,
  SponsorsReserve,
} from 'assets/images';
import { useTranslation } from 'react-i18next';

export const SponsorLogo = ({ version }) => {
  const { t } = useTranslation();

  return (
    <>
      {version === 'mobile' ? (
        <>
          <div className={s.wrap}>
            <img
              className={s.sponsorsReserveCover}
              src={SponsorsReserve}
              alt="Sponsors"
            />
          </div>
          <span className={s.LvivskeBearLogoSlogan}>{t('sponsor')}</span>
        </>
      ) : (
        <>
          <div className={s.wrap}>
            <div className={s.flex}>
              <div className={s.flexWrap}>
                <picture>
                  <source type="image/webp" srcSet={LvivskeBear} />
                  <img
                    className={s.LvivskeBearLogo}
                    src={LvivskeBearReserve}
                    alt="Lvivske Bear Logo"
                    loading="lazy"
                  />
                </picture>
                <picture>
                  <source type="image/webp" srcSet={Ukrainefederation} />
                  <img
                    className={s.UkrainefederationLogo}
                    src={UkrainefederationReserve}
                    alt="Ukraine Football Federation Logo"
                    loading="lazy"
                  />
                </picture>
              </div>
              <span className={s.LvivskeBearLogoSlogan}>{t('sponsor')}</span>
            </div>
          </div>
        </>
      )}
    </>
  );
};
