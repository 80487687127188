import { useState, useEffect } from 'react';
import { Modal, PlayerCard, FBShare } from 'component';
import { useTranslation } from 'react-i18next';
import homeSelectors from 'redux/home/home-selectors';
import { useSelector } from 'react-redux';
import s from './style.module.scss';

export const FullFiledModalVote = ({ close, idPlayer = 40, version }) => {
  const player = useSelector(homeSelectors.getPlayer);
  const [choicePlayer, setChoicePlayer] = useState();
  const { t } = useTranslation();

  useEffect(() => {
    const choice = [...player].find(el => el.id === idPlayer);
    setChoicePlayer(choice);
  }, [idPlayer, player]);

  return (
    <Modal onClose={close} bgColor={'#093A29'}>
      <div className={s.wrap}>
        <strong className={s.text}>{t('full_filed_text')} </strong>
        {choicePlayer && (
          <div className={s.card}>
            <PlayerCard
              data={choicePlayer}
              isIncludeBtn={false}
              isIncludeChart={true}
            />
          </div>
        )}
        <div className={s.wrapShare}>
          <strong className={s.slogan}>{t('share')} </strong>
          <FBShare
            name={choicePlayer?.name}
            id={choicePlayer?.id}
            version={version}
          />
        </div>

        <button type="button" className={s.btn} onClick={() => close()}>
          ок
        </button>
      </div>
    </Modal>
  );
};
