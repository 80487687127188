const getUserName = state => state.auth.userName;
const getIsLoggedGoogleIn = state => state.auth.isLoggedGoogleIn;
const getIsLoggedFacebookIn = state => state.auth.isLoggedFacebookIn;
const getUserToken = state => state.auth.token;
const getIsRefreshing = state => state.auth.isRefreshing;
const getUserGooglEmail = state => state.auth.userEmailGoogle;
const getUserEmailFacebook = state => state.auth.userEmailFacebook;

const authSelectors = {
  getUserName,
  getIsLoggedGoogleIn,
  getIsLoggedFacebookIn,
  getUserToken,
  getUserGooglEmail,
  getIsRefreshing,
  getUserEmailFacebook,
};
export default authSelectors;
