import { createSlice } from '@reduxjs/toolkit';
import operation from './personalCab-operation';

const initialState = {
  isLoading: false,
  profile: {},
};

const personalCabSlice = createSlice({
  name: 'personalCab',
  initialState,
  extraReducers: {
    //====getPersonalData====//
    [operation.getProfile.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getProfile.fulfilled]: (state, { payload }) => {
      state.profile = { ...payload };
      state.isLoading = true;
    },
    [operation.getProfile.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====deleteProfile====//
    [operation.deleteProfile.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.deleteProfile.fulfilled]: (state, { payload }) => {
      state.profile = {};
      state.isLoading = true;
    },
    [operation.deleteProfile.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },
  },
});
export default personalCabSlice.reducer;
