import {
  Footer,
  Main,
  Header,
  AuthModal,
  FullFiledModalVote,
} from 'modules/index';
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import authSelectors from 'redux/auth/auth-selectors';
import operationData from 'redux/home/home-operation';
import homeSelectors from 'redux/home/home-selectors';
import { Size } from 'middleware';
import { Loader } from 'component';
import { Helmet } from 'react-helmet-async';
import StatisticModal from "../../modules/StatisticModal";
import { toast } from 'react-toastify';

export const HomePage = () => {
  const [isOpenStatisticModal, setIsOpenStatisticModal] = useState(false)
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [idPlayer, setIdPlayer] = useState(false);
  const [isOpenResponseModal, setIsOpenResponseModal] = useState(false);
  const token = useSelector(authSelectors.getUserToken);
  const gameId = useSelector(homeSelectors.getGameId);
  const isLoading = useSelector(homeSelectors.getIsLoading);
  const { t, i18n } = useTranslation();
  const refLang = useRef();
  const dispatch = useDispatch();
  const [size, setSize] = useState(0);
  const [statisticPlayer, setStatisticPlayer] = useState({})

  useEffect(() => {
    console.log('12123123 work!')
    dispatch(operationData.getInfo());
    const refreshIntervalId = setInterval(() => {
      dispatch(operationData.getData({}));
    }, 120000);
    refLang.current = i18n.language;
    return () => clearInterval(refreshIntervalId);
  }, []);

  useEffect(() => {
    if (refLang.current === i18n.language) return;
    refLang.current = i18n.language;
    dispatch(operationData.changeLang());
  }, [i18n.language]);

  useEffect(() => {
    const lsKey = localStorage.getItem("date")
    if (lsKey) {
      const today = new Date()
      const dayFromStorage = new Date(Date.parse(lsKey))
      today.setHours(0, 0, 0, 0)
      dayFromStorage.setHours(0, 0, 0, 0)

      if (today > dayFromStorage) {
        localStorage.removeItem("date")
        localStorage.removeItem("playerId")
        console.log("CLEAR")
      }
    }
  }, []);

  const close = () => {
    if (isOpenModal) setIsOpenModal(false);
    if (isOpenResponseModal) setIsOpenResponseModal(false);
    if (isOpenStatisticModal) setIsOpenStatisticModal(false)
  };

  const handelVoice = (idPlayer, turnstileToken) => {
    dispatchVote(idPlayer, turnstileToken);
  };

  const handleStatisticModal = (payload) => {
    setIsOpenStatisticModal(payload.isOpen)
    setStatisticPlayer(payload.data)
  }

  const dispatchVote = (id, turnstileToken) => {
    new Promise((resolve, reject) => {
      dispatch(
        operationData.postVote({
          player_id: id,
          game_id: gameId,
          turnstile_token: turnstileToken,
          onResolve: resolve,
          onReject: reject,
        }),
      );
    })
      .then(data => {
        setIsOpenResponseModal(true);
        setIdPlayer(id);
        localStorage.setItem('playerId', id);
        toast.success('Ваш голос успішно зараховано!', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .finally(() => {});
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('site_title')}</title>
      </Helmet>
      {isLoading ? (
        <div
          className="adaptive"
          style={
            size < 768
              ? {
                  transform: `scale(${size / 250})`,
                  transformOrigin: 'top left',
                }
              : {
                  transform: `scale(${size / 1920})`,
                  transformOrigin: 'top left ',
                }
          }
        >
          <Size setSize={setSize}>
            <Header />
            <Main
              handelVoice={handelVoice}
              handleStatisticModal={handleStatisticModal}
            />
            <Footer />
            { isOpenStatisticModal &&
              <StatisticModal
                close={close}
                data={statisticPlayer}
              />
            }
            {isOpenModal && (
              <AuthModal close={close} dispatchVote={dispatchVote} />
            )}
            {isOpenResponseModal && (
              <FullFiledModalVote
                close={close}
                idPlayer={idPlayer}
                version={size >= 768 ? 'desktop' : 'mobile'}
              />
            )}
          </Size>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
