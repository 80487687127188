import { useState, useEffect } from 'react';
import { VideoItem, Paginate, NewsItem, Modal } from 'component';
import s from './style.module.scss';
import { NavLink } from 'react-router-dom';
import slugify from 'slugify';
import { nanoid } from '@reduxjs/toolkit';

export const Slider = ({
  type = 'video',
  data,
  onNextPaginate,
  limit,
  isLastPage,
  version,
  page,
  refresh = false,
  setRefResh,
}) => {
  const [index, setIndex] = useState(0);
  const [viewData, setViewData] = useState([]);
  const [userPage, setUserPage] = useState(1);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [choiceVideo, setChoiceVideo] = useState('');

  useEffect(() => {
    if (refresh) {
      setIndex(0);
      setUserPage(1);
      setRefResh(!refresh);
    }
  }, [refresh]);

  useEffect(() => {
    if (index === 0) {
      setViewData([...data]);
    } else {
      setViewData([...data].slice(index, data.length));
    }
  }, [data]);

  useEffect(() => {
    setViewData([...data].slice(0, limit));
    setIndex(0);
    setUserPage(1);
  }, [version]);

  const close = () => setIsOpenModal(false);

  const handelBack = () => {
    switch (version) {
      case 'mobile':
        setIndex(index - 1);
        break;
      case 'desktop':
        setViewData([...data].slice(index - limit, limit * (userPage - 1)));
        setUserPage(userPage - 1);
        setIndex(index - limit);
        break;

      default:
        break;
    }
  };

  const handelNext = () => {
    switch (version) {
      case 'mobile':
        if (index + 1 === data.length) {
          onNextPaginate();
          setIndex(index + 1);
        } else {
          setIndex(index + 1);
        }
        break;
      case 'desktop':
        if (page === userPage) {
          onNextPaginate();
        } else {
          setViewData([...data].slice(index + limit, limit * (userPage + 1)));
        }
        setUserPage(userPage + 1);
        setIndex(index + limit);
        break;
      default:
        break;
    }
  };

  return (
    <Paginate
      back={handelBack}
      next={handelNext}
      isPaginate={true}
      isBack={!(index === 0)}
      isNext={
        version === 'mobile'
          ? !(isLastPage && index + 1 === data.length)
          : !(isLastPage && page === userPage)
      }
    >
      {type === 'video' ? (
        version === 'mobile' ? (
          data[index] && (
            <div className={s.wrap}>
              <VideoItem src={data[index]?.embed} style={s.video} />
            </div>
          )
        ) : (
          <>
            <ul className={s.wrap}>
              {viewData.map(el => (
                <li
                  key={nanoid()}
                  className={s.thumb}
                  onClick={() => {
                    setChoiceVideo(el.embed);
                    setIsOpenModal(true);
                  }}
                >
                  <img src={el.preview_image} alt="" className={s.video} />
                </li>
              ))}
            </ul>
            {isOpenModal && (
              <Modal onClose={close} bgColor={'transparent'}>
                <div className={s.wrapModal}>
                  <VideoItem src={choiceVideo} style={s.videoFrame} autoPlay />
                </div>
              </Modal>
            )}
          </>
        )
      ) : version === 'mobile' ? (
        data[index] && (
          <div className={s.wrapNews}>
            <NavLink
              to={`/news/${slugify(`${data[index]?.slug} ${data[index]?.id}`, {
                replacement: '-',
                lower: true,
                trim: true,
              })}`}
            >
              <NewsItem data={data[index]} version={version} />
            </NavLink>
          </div>
        )
      ) : (
        <ul className={s.wrapNews}>
          {viewData.map(el => (
            <li className={s.newsItemBox} key={nanoid()}>
              <NavLink
                to={`/news/${slugify(`${el.slug} ${el.id}`, {
                  replacement: '-',
                  lower: true,
                  trim: true,
                })}`}
                style={{ width: '100%' }}
                onClick={() =>
                  window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                  })
                }
              >
                <NewsItem data={el} />
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </Paginate>
  );
};
