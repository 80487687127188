import { Modal } from 'component';
import s from './style.module.scss';

export const QuestionModal = ({
  close,
  questionText,
  rejectText,
  acceptText,
  actionHandel,
}) => {
  return (
    <Modal onClose={close}>
      <div className={s.question}>
        <strong className={s.text}>{questionText}</strong>
        <div className={s.box_btn}>
          <button className={s.btn_reject} onClick={close}>
            {rejectText}
          </button>
          <button className={s.btn_accept} onClick={actionHandel}>
            {acceptText}
          </button>
        </div>
      </div>
    </Modal>
  );
};
