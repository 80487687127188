import { createSlice } from '@reduxjs/toolkit';
import operation from './news-operation';

const initialState = {
  description: '',
  id: 0,
  photo: '',
  slug: '0',
  title: '0',
  isLoading: false,
};

const newsSlice = createSlice({
  name: 'news',
  initialState,
  extraReducers: {
    //====getNews====//
    [operation.getNews.pending]: (state, action) => {
      state.isLoading = false;
    },
    [operation.getNews.fulfilled]: (state, { payload }) => {
      state.description = payload.data.description;
      state.id = payload.data.id;
      state.photo = payload.data.photo;
      state.slug = payload.data.slug;
      state.title = payload.data.title;
      state.isLoading = true;
    },
    [operation.getNews.rejected]: (state, { payload }) => {
      state.isLoading = false;
    },

    //====changeLang====//
    [operation.changeLang.fulfilled]: (state, { payload }) => {
      state.description = payload.data.description;
      state.id = payload.data.id;
      state.photo = payload.data.photo;
      state.slug = payload.data.slug;
      state.title = payload.data.title;
    },
  },
});
export default newsSlice.reducer;
