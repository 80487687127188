import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

axios.defaults.baseURL = process.env.REACT_APP_API_URL_DEV;
const token = {
  set(token) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
  unset() {
    axios.defaults.headers.common['Authorization'] = ``;
  },
};

const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ credentials, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('auth', credentials);
      token.set(data.data.token);
      toast.success(localStorage.getItem('i18nextLng') !== 'uk'
        ? `Authorization susses` : 'Авторизація успішна', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      onResolve();
      return data.data;
    } catch (error) {
      onReject(error);
      return rejectWithValue(error);
    }
  },
);
const refreshCurrentUser = createAsyncThunk(
  'auth/refreshCurrentUser',
  async (_, thunkApi) => {
    const state = thunkApi.getState();
    const persistedToken = state.auth.token;
    if (persistedToken === null) {
      token.unset();
      return thunkApi.rejectWithValue(state);
    } else {
      try {
        const { data } = await axios.get('token/verify', {
          headers: { Authorization: `Bearer ${persistedToken}` },
        });
        token.set(data.data.token);
        return data.data.token;
      } catch (error) {
        return thunkApi.rejectWithValue(state);
      }
    }
  },
);

const googleIn = createAsyncThunk('auth/googleIn', ({ email, name }) => {
  return { email, name };
});

const facebookIn = createAsyncThunk('auth/facebookIn', ({ email, name }) => {
  return { email, name };
});

const clearToken = createAsyncThunk('auth/clearToken', _ => '');

export default {
  signIn,
  googleIn,
  facebookIn,
  refreshCurrentUser,
  clearToken,
};
