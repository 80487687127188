export const VideoItem = ({ src, style, autoPlay = false }) => {
  return (
    <iframe
      src={`${src}${autoPlay ? '?autoplay=true' : ''}`}
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      enablejsapi={'1'}
      origin={'http://localhost:3000'}
      className={style}
      autoPlay={true}
    ></iframe>
  );
};
